/**
 * @fileoverview gRPC-Web generated client stub for search
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.search = require('./Search_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.search.SearchServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.search.SearchServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.QueryRequest,
 *   !proto.search.QueryResponse>}
 */
const methodDescriptor_SearchService_Query = new grpc.web.MethodDescriptor(
  '/search.SearchService/Query',
  grpc.web.MethodType.UNARY,
  proto.search.QueryRequest,
  proto.search.QueryResponse,
  /**
   * @param {!proto.search.QueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.QueryResponse.deserializeBinary
);


/**
 * @param {!proto.search.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.QueryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.QueryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.query =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Query',
      request,
      metadata || {},
      methodDescriptor_SearchService_Query,
      callback);
};


/**
 * @param {!proto.search.QueryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.QueryResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.query =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Query',
      request,
      metadata || {},
      methodDescriptor_SearchService_Query);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.FacetRequest,
 *   !proto.search.FacetResponse>}
 */
const methodDescriptor_SearchService_Facet = new grpc.web.MethodDescriptor(
  '/search.SearchService/Facet',
  grpc.web.MethodType.UNARY,
  proto.search.FacetRequest,
  proto.search.FacetResponse,
  /**
   * @param {!proto.search.FacetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.FacetResponse.deserializeBinary
);


/**
 * @param {!proto.search.FacetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.FacetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.FacetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.facet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Facet',
      request,
      metadata || {},
      methodDescriptor_SearchService_Facet,
      callback);
};


/**
 * @param {!proto.search.FacetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.FacetResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.facet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Facet',
      request,
      metadata || {},
      methodDescriptor_SearchService_Facet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.FacetRequest,
 *   !proto.search.FacetResponse>}
 */
const methodDescriptor_SearchService_FacetClassCode = new grpc.web.MethodDescriptor(
  '/search.SearchService/FacetClassCode',
  grpc.web.MethodType.UNARY,
  proto.search.FacetRequest,
  proto.search.FacetResponse,
  /**
   * @param {!proto.search.FacetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.FacetResponse.deserializeBinary
);


/**
 * @param {!proto.search.FacetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.FacetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.FacetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.facetClassCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/FacetClassCode',
      request,
      metadata || {},
      methodDescriptor_SearchService_FacetClassCode,
      callback);
};


/**
 * @param {!proto.search.FacetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.FacetResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.facetClassCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/FacetClassCode',
      request,
      metadata || {},
      methodDescriptor_SearchService_FacetClassCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.FacetRequest,
 *   !proto.search.FacetResponse>}
 */
const methodDescriptor_SearchService_FacetPerioClassCode = new grpc.web.MethodDescriptor(
  '/search.SearchService/FacetPerioClassCode',
  grpc.web.MethodType.UNARY,
  proto.search.FacetRequest,
  proto.search.FacetResponse,
  /**
   * @param {!proto.search.FacetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.FacetResponse.deserializeBinary
);


/**
 * @param {!proto.search.FacetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.FacetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.FacetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.facetPerioClassCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/FacetPerioClassCode',
      request,
      metadata || {},
      methodDescriptor_SearchService_FacetPerioClassCode,
      callback);
};


/**
 * @param {!proto.search.FacetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.FacetResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.facetPerioClassCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/FacetPerioClassCode',
      request,
      metadata || {},
      methodDescriptor_SearchService_FacetPerioClassCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.UpdateRequest,
 *   !proto.search.UpdateResponse>}
 */
const methodDescriptor_SearchService_Update = new grpc.web.MethodDescriptor(
  '/search.SearchService/Update',
  grpc.web.MethodType.UNARY,
  proto.search.UpdateRequest,
  proto.search.UpdateResponse,
  /**
   * @param {!proto.search.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.UpdateResponse.deserializeBinary
);


/**
 * @param {!proto.search.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.UpdateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.UpdateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Update',
      request,
      metadata || {},
      methodDescriptor_SearchService_Update,
      callback);
};


/**
 * @param {!proto.search.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.UpdateResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Update',
      request,
      metadata || {},
      methodDescriptor_SearchService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.DocumentRequest,
 *   !proto.search.DocumentResponse>}
 */
const methodDescriptor_SearchService_Document = new grpc.web.MethodDescriptor(
  '/search.SearchService/Document',
  grpc.web.MethodType.UNARY,
  proto.search.DocumentRequest,
  proto.search.DocumentResponse,
  /**
   * @param {!proto.search.DocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.search.DocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.document =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Document',
      request,
      metadata || {},
      methodDescriptor_SearchService_Document,
      callback);
};


/**
 * @param {!proto.search.DocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.document =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Document',
      request,
      metadata || {},
      methodDescriptor_SearchService_Document);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.GetHotRequest,
 *   !proto.search.GetHotResponse>}
 */
const methodDescriptor_SearchService_GetHot = new grpc.web.MethodDescriptor(
  '/search.SearchService/GetHot',
  grpc.web.MethodType.UNARY,
  proto.search.GetHotRequest,
  proto.search.GetHotResponse,
  /**
   * @param {!proto.search.GetHotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.GetHotResponse.deserializeBinary
);


/**
 * @param {!proto.search.GetHotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.GetHotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.GetHotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.getHot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/GetHot',
      request,
      metadata || {},
      methodDescriptor_SearchService_GetHot,
      callback);
};


/**
 * @param {!proto.search.GetHotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.GetHotResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.getHot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/GetHot',
      request,
      metadata || {},
      methodDescriptor_SearchService_GetHot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.GetJinCaiRequest,
 *   !proto.search.GetJinCaiResponse>}
 */
const methodDescriptor_SearchService_GetJinCai = new grpc.web.MethodDescriptor(
  '/search.SearchService/GetJinCai',
  grpc.web.MethodType.UNARY,
  proto.search.GetJinCaiRequest,
  proto.search.GetJinCaiResponse,
  /**
   * @param {!proto.search.GetJinCaiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.GetJinCaiResponse.deserializeBinary
);


/**
 * @param {!proto.search.GetJinCaiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.GetJinCaiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.GetJinCaiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.getJinCai =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/GetJinCai',
      request,
      metadata || {},
      methodDescriptor_SearchService_GetJinCai,
      callback);
};


/**
 * @param {!proto.search.GetJinCaiRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.GetJinCaiResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.getJinCai =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/GetJinCai',
      request,
      metadata || {},
      methodDescriptor_SearchService_GetJinCai);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.DocumentUrlRequest,
 *   !proto.search.DocumentUrlResponse>}
 */
const methodDescriptor_SearchService_DocumentUrl = new grpc.web.MethodDescriptor(
  '/search.SearchService/DocumentUrl',
  grpc.web.MethodType.UNARY,
  proto.search.DocumentUrlRequest,
  proto.search.DocumentUrlResponse,
  /**
   * @param {!proto.search.DocumentUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.DocumentUrlResponse.deserializeBinary
);


/**
 * @param {!proto.search.DocumentUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.DocumentUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.DocumentUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.documentUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/DocumentUrl',
      request,
      metadata || {},
      methodDescriptor_SearchService_DocumentUrl,
      callback);
};


/**
 * @param {!proto.search.DocumentUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.DocumentUrlResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.documentUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/DocumentUrl',
      request,
      metadata || {},
      methodDescriptor_SearchService_DocumentUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.TransDocRequest,
 *   !proto.search.TransDocResponse>}
 */
const methodDescriptor_SearchService_TransDoc = new grpc.web.MethodDescriptor(
  '/search.SearchService/TransDoc',
  grpc.web.MethodType.UNARY,
  proto.search.TransDocRequest,
  proto.search.TransDocResponse,
  /**
   * @param {!proto.search.TransDocRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.TransDocResponse.deserializeBinary
);


/**
 * @param {!proto.search.TransDocRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.TransDocResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.TransDocResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.transDoc =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/TransDoc',
      request,
      metadata || {},
      methodDescriptor_SearchService_TransDoc,
      callback);
};


/**
 * @param {!proto.search.TransDocRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.TransDocResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.transDoc =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/TransDoc',
      request,
      metadata || {},
      methodDescriptor_SearchService_TransDoc);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.DownloadRequest,
 *   !proto.search.DownloadResponse>}
 */
const methodDescriptor_SearchService_Download = new grpc.web.MethodDescriptor(
  '/search.SearchService/Download',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.search.DownloadRequest,
  proto.search.DownloadResponse,
  /**
   * @param {!proto.search.DownloadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.DownloadResponse.deserializeBinary
);


/**
 * @param {!proto.search.DownloadRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.search.DownloadResponse>}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.download =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/search.SearchService/Download',
      request,
      metadata || {},
      methodDescriptor_SearchService_Download);
};


/**
 * @param {!proto.search.DownloadRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.search.DownloadResponse>}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/search.SearchService/Download',
      request,
      metadata || {},
      methodDescriptor_SearchService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.CheckStatusRequest,
 *   !proto.search.CheckStatusResponse>}
 */
const methodDescriptor_SearchService_CheckStatus = new grpc.web.MethodDescriptor(
  '/search.SearchService/CheckStatus',
  grpc.web.MethodType.UNARY,
  proto.search.CheckStatusRequest,
  proto.search.CheckStatusResponse,
  /**
   * @param {!proto.search.CheckStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.CheckStatusResponse.deserializeBinary
);


/**
 * @param {!proto.search.CheckStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.CheckStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.CheckStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.checkStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/CheckStatus',
      request,
      metadata || {},
      methodDescriptor_SearchService_CheckStatus,
      callback);
};


/**
 * @param {!proto.search.CheckStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.CheckStatusResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.checkStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/CheckStatus',
      request,
      metadata || {},
      methodDescriptor_SearchService_CheckStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.ListRequest,
 *   !proto.search.ListResponse>}
 */
const methodDescriptor_SearchService_List = new grpc.web.MethodDescriptor(
  '/search.SearchService/List',
  grpc.web.MethodType.UNARY,
  proto.search.ListRequest,
  proto.search.ListResponse,
  /**
   * @param {!proto.search.ListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.ListResponse.deserializeBinary
);


/**
 * @param {!proto.search.ListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.ListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.ListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/List',
      request,
      metadata || {},
      methodDescriptor_SearchService_List,
      callback);
};


/**
 * @param {!proto.search.ListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.ListResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/List',
      request,
      metadata || {},
      methodDescriptor_SearchService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.DeleteRequest,
 *   !proto.search.DeleteResponse>}
 */
const methodDescriptor_SearchService_Delete = new grpc.web.MethodDescriptor(
  '/search.SearchService/Delete',
  grpc.web.MethodType.UNARY,
  proto.search.DeleteRequest,
  proto.search.DeleteResponse,
  /**
   * @param {!proto.search.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.DeleteResponse.deserializeBinary
);


/**
 * @param {!proto.search.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.DeleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.DeleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Delete',
      request,
      metadata || {},
      methodDescriptor_SearchService_Delete,
      callback);
};


/**
 * @param {!proto.search.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.DeleteResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Delete',
      request,
      metadata || {},
      methodDescriptor_SearchService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.SendEmailRequest,
 *   !proto.search.SendEmailResponse>}
 */
const methodDescriptor_SearchService_SendEmail = new grpc.web.MethodDescriptor(
  '/search.SearchService/SendEmail',
  grpc.web.MethodType.UNARY,
  proto.search.SendEmailRequest,
  proto.search.SendEmailResponse,
  /**
   * @param {!proto.search.SendEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.SendEmailResponse.deserializeBinary
);


/**
 * @param {!proto.search.SendEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.SendEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.SendEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.sendEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/SendEmail',
      request,
      metadata || {},
      methodDescriptor_SearchService_SendEmail,
      callback);
};


/**
 * @param {!proto.search.SendEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.SendEmailResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.sendEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/SendEmail',
      request,
      metadata || {},
      methodDescriptor_SearchService_SendEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.ShareRequest,
 *   !proto.search.ShareResponse>}
 */
const methodDescriptor_SearchService_Share = new grpc.web.MethodDescriptor(
  '/search.SearchService/Share',
  grpc.web.MethodType.UNARY,
  proto.search.ShareRequest,
  proto.search.ShareResponse,
  /**
   * @param {!proto.search.ShareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.ShareResponse.deserializeBinary
);


/**
 * @param {!proto.search.ShareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.ShareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.ShareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.share =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Share',
      request,
      metadata || {},
      methodDescriptor_SearchService_Share,
      callback);
};


/**
 * @param {!proto.search.ShareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.ShareResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.share =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Share',
      request,
      metadata || {},
      methodDescriptor_SearchService_Share);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.CollRequest,
 *   !proto.search.CollResponse>}
 */
const methodDescriptor_SearchService_Coll = new grpc.web.MethodDescriptor(
  '/search.SearchService/Coll',
  grpc.web.MethodType.UNARY,
  proto.search.CollRequest,
  proto.search.CollResponse,
  /**
   * @param {!proto.search.CollRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.CollResponse.deserializeBinary
);


/**
 * @param {!proto.search.CollRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.CollResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.CollResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.coll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/Coll',
      request,
      metadata || {},
      methodDescriptor_SearchService_Coll,
      callback);
};


/**
 * @param {!proto.search.CollRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.CollResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.coll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/Coll',
      request,
      metadata || {},
      methodDescriptor_SearchService_Coll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.CollListRequest,
 *   !proto.search.CollListResponse>}
 */
const methodDescriptor_SearchService_CollList = new grpc.web.MethodDescriptor(
  '/search.SearchService/CollList',
  grpc.web.MethodType.UNARY,
  proto.search.CollListRequest,
  proto.search.CollListResponse,
  /**
   * @param {!proto.search.CollListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.CollListResponse.deserializeBinary
);


/**
 * @param {!proto.search.CollListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.CollListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.CollListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.collList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/CollList',
      request,
      metadata || {},
      methodDescriptor_SearchService_CollList,
      callback);
};


/**
 * @param {!proto.search.CollListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.CollListResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.collList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/CollList',
      request,
      metadata || {},
      methodDescriptor_SearchService_CollList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.DelCollRequest,
 *   !proto.search.DelCollResponse>}
 */
const methodDescriptor_SearchService_DelColl = new grpc.web.MethodDescriptor(
  '/search.SearchService/DelColl',
  grpc.web.MethodType.UNARY,
  proto.search.DelCollRequest,
  proto.search.DelCollResponse,
  /**
   * @param {!proto.search.DelCollRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.DelCollResponse.deserializeBinary
);


/**
 * @param {!proto.search.DelCollRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.DelCollResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.DelCollResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.delColl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/DelColl',
      request,
      metadata || {},
      methodDescriptor_SearchService_DelColl,
      callback);
};


/**
 * @param {!proto.search.DelCollRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.DelCollResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.delColl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/DelColl',
      request,
      metadata || {},
      methodDescriptor_SearchService_DelColl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.GetRemarkTypeRequest,
 *   !proto.search.GetRemarkTypeResponse>}
 */
const methodDescriptor_SearchService_GetRemarkType = new grpc.web.MethodDescriptor(
  '/search.SearchService/GetRemarkType',
  grpc.web.MethodType.UNARY,
  proto.search.GetRemarkTypeRequest,
  proto.search.GetRemarkTypeResponse,
  /**
   * @param {!proto.search.GetRemarkTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.GetRemarkTypeResponse.deserializeBinary
);


/**
 * @param {!proto.search.GetRemarkTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.GetRemarkTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.GetRemarkTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.getRemarkType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/GetRemarkType',
      request,
      metadata || {},
      methodDescriptor_SearchService_GetRemarkType,
      callback);
};


/**
 * @param {!proto.search.GetRemarkTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.GetRemarkTypeResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.getRemarkType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/GetRemarkType',
      request,
      metadata || {},
      methodDescriptor_SearchService_GetRemarkType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.search.CheckRemarkRequest,
 *   !proto.search.CheckRemarkResponse>}
 */
const methodDescriptor_SearchService_CheckRemark = new grpc.web.MethodDescriptor(
  '/search.SearchService/CheckRemark',
  grpc.web.MethodType.UNARY,
  proto.search.CheckRemarkRequest,
  proto.search.CheckRemarkResponse,
  /**
   * @param {!proto.search.CheckRemarkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.search.CheckRemarkResponse.deserializeBinary
);


/**
 * @param {!proto.search.CheckRemarkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.search.CheckRemarkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.search.CheckRemarkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.search.SearchServiceClient.prototype.checkRemark =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/search.SearchService/CheckRemark',
      request,
      metadata || {},
      methodDescriptor_SearchService_CheckRemark,
      callback);
};


/**
 * @param {!proto.search.CheckRemarkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.search.CheckRemarkResponse>}
 *     Promise that resolves to the response
 */
proto.search.SearchServicePromiseClient.prototype.checkRemark =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/search.SearchService/CheckRemark',
      request,
      metadata || {},
      methodDescriptor_SearchService_CheckRemark);
};


module.exports = proto.search;

