<template>
  <div class="home">
    <div style="width: 100%;background: #FFFFFF;display: flex;align-items: end;justify-content: center">
      <div class="home-content">
        <div style="width: 575px;display: flex;align-items: end;">
          <img src="../assets/logo.png" alt="" width="203" height="70"
               style="cursor: pointer;">
          <div
              style="margin-left: 102px;width: 270px;display: flex;align-items: center;justify-content: space-between;">
            <div
                style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;cursor:pointer;height: 72px;display: flex;align-items: center;justify-content: center;"
                :class="this.$route.path==='/'||this.$route.path==='/index'?'active':'no-active'"
                @click="()=>this.$router.push('/')">首页
            </div>
            <div @click="toMore" :class="this.$route.path==='/navigation'?'active':'no-active'"
                 style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;cursor:pointer;height: 72px;display: flex;align-items: center;justify-content: center"
            >
              期刊
            </div>
            <div style="border-bottom: 4px solid white;display: flex;justify-content: space-between;" @click="toWesi">
              <div
                  style="cursor:pointer;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;">
                翻译站点
              </div>
            </div>
            <div style="width: 27px;border-bottom: 4px solid white;height: 76px;display: flex;align-items: center;">
              <img v-show="!$store.state.trans" style="cursor: pointer" src="../assets/译icon-A.png" alt=""
                   @click="transMagas"/>
              <img v-show="$store.state.trans" style="cursor: pointer" src="../assets/译icon-文原.png" alt=""
                   @click="transMagas"/>
            </div>
          </div>
        </div>
        <div style="display:flex;height: 76px;display: flex;align-items: center;">
          <div v-if="groupUserInfo==='机构登录'"
               style="margin-right: 15px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #2C211E;cursor: pointer"
               @click="showIframe('/loginTwo?code=haoyi')">
            机构登录
          </div>
          <div v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'" class="user-info"
               @click="showIframe('/loginTwo?code=haoyi')">
            <el-popover
                class="jigou"
                placement="bottom"
                title=""
                width="120"
                trigger="hover">
              <div
                  style="cursor:pointer;width: 120px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;line-height: 36px">
                <div v-for="(item,index) in groupUserInfos" @click="toCenter" style="display: flex">
                  <div :title="item.name"
                       style="width: 80px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
                    {{
                      item.name
                    }}
                  </div>
                  <span @click="logOut(item.name)">退出</span>
                </div>
              </div>
              <div :title="groupUserInfo"
                   style="max-width: 300px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap"
                   slot="reference">{{ groupUserInfo }}
              </div>
            </el-popover>
          </div>
          <div v-if="userInfo===''" class="login-btn" @click="showIframe('/loginTwo?code=haoyi')">
            登录
          </div>
          <div v-if="userInfo===''" @click="showIframe('/registerTwo?code=haoyi')"
               style="margin-left: 50px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #2C211E;cursor: pointer">
            注册
          </div>
          <div v-if="userInfo!==''" class="user-info">
            <el-popover
                class="geren"
                placement="bottom"
                title=""
                width="120"
                trigger="hover">
              <div
                  style="cursor:pointer;width: 120px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;line-height: 36px">
                <div @click="toList">个人中心</div>
                <div @click="toCenter">账号设置</div>
                <div @click="logOut()">退出</div>
              </div>
              <div :title="userInfo"
                   slot="reference"
                   style="max-width: 300px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">欢迎，{{
                  userInfo
                }}
              </div>
            </el-popover>
            <span @click="toCenter">
            <img v-if="userType==='0'" class="user-img" src="../assets/person_no.png" alt="">
            <img v-if="userType==='1'" class="user-img" src="../assets/child_no.png" alt="">
            <img v-if="userType==='2'" class="user-img" src="../assets/roaming_no.png" alt="">
        </span>
          </div>
        </div>
      </div>
    </div>
    <div class="head">
      <el-header class="head-el">
        <div class="head-one">
          <div class="check-source">
            <div :class="collection==='Periodical'?'check-active':'check-no-active'"
                 @click="checkSource('Periodical')">文章
            </div>
            <div :class="collection==='Magazine'?'check-active':'check-no-active'"
                 @click="checkSource('Magazine')">期刊
            </div>
          </div>
          <div class="search-input">
            <el-input @change="toSearch" placeholder="请输入关键词" v-model="word" class="input-with-select">
              <el-select v-show="collection==='Periodical'" v-model="select" slot="append">
                <el-option label="所有领域" value="所有领域"></el-option>
                <el-option label="题名" value="题名"></el-option>
                <el-option label="作者" value="作者"></el-option>
                <el-option label="刊名" value="刊名"></el-option>
                <el-option label="作者单位" value="作者单位"></el-option>
                <el-option label="关键词" value="关键词"></el-option>
              </el-select>
              <el-select v-show="collection==='Magazine'" v-model="select" slot="append">
                <el-option label="所有领域" value="所有领域"></el-option>
                <el-option label="题名" value="题名"></el-option>
              </el-select>
              <i slot="suffix" v-if="word" @click="clearWord">
                <img src="../assets/clear.png" alt="">
              </i>
              <el-button slot="append" class="btn-search" @click="toSearch">
                搜索
              </el-button>
            </el-input>
          </div>
        </div>
      </el-header>
    </div>
    <div style="width: 100%;display: flex;align-items: center;justify-content: center; z-index: 10;
      height: 85px;background: #FFFFFF;box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.08);border-radius: 5px;">
      <div class="nav-content">
        <div style="display: flex;font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
          期刊导航
          <div style="width: 2px;height: 31px;background: #DDDDDD;margin: 0 20px"></div>
          <div
              style="min-width:1000px;max-width:1400px;overflow:hidden;white-space:nowrap;display: flex;align-items: center;text-overflow: ellipsis">
            <div
                style="padding-right:35px;cursor: pointer;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                v-for="(item,index) in facetFieldDataClassCode" :index="index">
              <span @click="toNavigation(item.label)">{{ item.name }}</span>
            </div>
            ...
          </div>
        </div>
        <div @click="toMore"
             style="cursor: pointer;width: 67px;height: 31px;border: 1px solid #999999;opacity: 0.96;border-radius: 5px;
            font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
            display: flex;align-items: center;justify-content: center;">
          更多>>
        </div>
      </div>
    </div>
    <div style="width: 100%;display: flex;flex-flow: column;align-items: center;    background: #ffffff;">
      <el-main>
        <div class="hot">
          <div style="justify-content: center;width: 100%;margin-top: 57px;margin-bottom:33px;display: flex;">
            <div style="font-size: 18px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;
                display: flex;align-items: center;justify-content: center">
              <div style="width: 54px;height: 2px;background: #E5E5E5;"></div>
              <div
                  style="margin: 0 10px;font-size: 30px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;">
                最热门的刊
              </div>
              <div style="width: 54px;height: 2px;background: #E5E5E5;"></div>
            </div>
          </div>
          <div class="mang-lists">
            <div v-for="(item,index) in (hot1||[])" class="mang-list-item">
              <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: item.id
                      }}">
                <img :src="'https://file.wesitrans.com/wesitrans/template/perio/img/'+ item.id+'.png'"
                     @error="e=>{e.target.src='https://file.wesitrans.com/wesitrans/template/perio/img/default_perio_img.png'}"
                     width="239" height="316" alt=""/>
                <div v-show="!transMaga" v-if="item.Title" :title="item.Title"
                     style="cursor: pointer;width: 239px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
                      margin-top:15px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                      margin-bottom: 20px;">
                  {{ item.Title }}
                </div>
                <div v-show="transMaga" v-if="item.Title_Translated" :title="item.Title_Translated"
                     style="cursor: pointer;width: 239px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
                     margin-top:15px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                     margin-bottom: 20px">
                  {{ item.Title_Translated }}
                </div>
                <div v-show="false" style="display: flex;justify-content: space-between;align-items: center;height: 50px;
                cursor: pointer;width: 239px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <div v-show="false">2022年02期</div>
                  <div v-show="false"
                       style="display: flex;align-items: center;justify-content: center;font-size: 12px;">
                    <img src="../assets/yinzi.png" alt="" style="margin-right: 6px"/>
                    54352
                  </div>
                </div>
              </router-link>
              <div style="width: 239px;height: 1px;background: #DDDDDD;"></div>
              <div
                  style="display: flex;margin-top: 20px;margin-bottom: 20px;width: 239px;justify-content: space-between;">
                <div
                    style="flex-flow: column;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img src="../assets/read.png" alt="" width="39" height="28"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                    <div style="margin-left: 8px">阅读</div>
                    <div style="margin-left: 8px">{{
                        formatCount(dataStatJson ? dataStatJson[item.id] ? dataStatJson[item.id].read || 0 : 0 : 0)
                      }}
                    </div>
                  </div>
                </div>
                <div
                    style="flex-flow: column;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img src="../assets/down.png" alt="" width="31" height="29"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                    <div style="margin-left: 8px">下载</div>
                    <span style="margin-left: 8px">{{
                        formatCount(dataStatJson ? dataStatJson[item.id] ? dataStatJson[item.id].down || 0 : 0 : 0)
                      }}</span>
                  </div>
                </div>
                <div @click="collDoc(item,index)"
                     style="flex-flow: column;cursor: pointer;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img v-show="!docIds[item.id]" src="../assets/collection.png" alt="" width="34"
                       height="29"/>
                  <img v-show="docIds[item.id]" src="../assets/collection-ok.png" alt="" width="34"
                       height="29" style="display: none"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;
                      font-weight: 400;color: #999999;">
                    <el-button type="text" :loading="collDocLoading===index" style="margin-left: 8px;padding: 0">
                        <span style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                          收藏
                        </span>
                    </el-button>
                    <span style="margin-left: 8px">{{ formatCount(collectJson[item.id] || 0) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 200%;height: 10px;background: #F5F5F5;"></div>
          <div class="mang-lists">
            <div v-for="(item,index) in (hot2||[])" class="mang-list-item">
              <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: item.id
                      }}">
                <img :src="'https://file.wesitrans.com/wesitrans/template/perio/img/'+ item.id+'.png'"
                     @error="e=>{e.target.src='https://file.wesitrans.com/wesitrans/template/perio/img/default_perio_img.png'}"
                     width="239" height="316" alt=""/>
                <div v-show="!transMaga" v-if="item.Title" :title="item.Title"
                     style="cursor: pointer;width: 239px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
                      margin-top:15px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;margin-bottom: 20px;">
                  {{ item.Title }}
                </div>
                <div v-show="transMaga" v-if="item.Title_Translated" :title="item.Title_Translated"
                     style="cursor: pointer;width: 239px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
                     margin-top:15px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                     margin-bottom: 20px">
                  {{ item.Title_Translated }}
                </div>
                <div v-show="false" style="display: flex;justify-content: space-between;align-items: center;height: 50px;
                cursor: pointer;width: 239px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <div v-show="false">2022年02期</div>
                  <div v-show="false"
                       style="display: flex;align-items: center;justify-content: center;font-size: 12px;">
                    <img src="../assets/yinzi.png" alt="" style="margin-right: 6px"/>
                    54352
                  </div>
                </div>
              </router-link>
              <div style="width: 239px;height: 1px;background: #DDDDDD;"></div>
              <div
                  style="display: flex;margin-top: 20px;margin-bottom: 20px;width: 239px;justify-content: space-between;">
                <div
                    style="flex-flow: column;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img src="../assets/read.png" alt="" width="39" height="28"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                    <div style="margin-left: 8px">阅读</div>
                    <div style="margin-left: 8px">{{
                        formatCount(dataStatJson ? dataStatJson[item.id] ? dataStatJson[item.id].read || 0 : 0 : 0)
                      }}
                    </div>
                  </div>
                </div>
                <div
                    style="flex-flow: column;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img src="../assets/down.png" alt="" width="31" height="29"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                    <div style="margin-left: 8px">下载</div>
                    <span style="margin-left: 8px">{{
                        formatCount(dataStatJson ? dataStatJson[item.id] ? dataStatJson[item.id].down || 0 : 0 : 0)
                      }}</span>
                  </div>
                </div>
                <div @click="collDoc(item,index)"
                     style="flex-flow: column;cursor: pointer;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img v-show="!docIds[item.id]" src="../assets/collection.png" alt="" width="34"
                       height="29"/>
                  <img v-show="docIds[item.id]" src="../assets/collection-ok.png" alt="" width="34"
                       height="29" style="display: none"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;
                      font-weight: 400;color: #999999;">
                    <el-button type="text" :loading="collDocLoading===index" style="margin-left: 8px;padding: 0">
                        <span style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                          收藏
                        </span>
                    </el-button>
                    <span style="margin-left: 8px">{{ formatCount(collectJson[item.id] || 0) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 200%;height: 10px;background: #F5F5F5;"></div>
          <div class="mang-lists">
            <div v-for="(item,index) in (hot3||[])" class="mang-list-item">
              <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: item.id
                      }}">
                <img :src="'https://file.wesitrans.com/wesitrans/template/perio/img/'+ item.id+'.png'"
                     @error="e=>{e.target.src='https://file.wesitrans.com/wesitrans/template/perio/img/default_perio_img.png'}"
                     width="239" height="316" alt=""/>
                <div v-show="!transMaga" v-if="item.Title" :title="item.Title"
                     style="cursor: pointer;width: 239px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
                      margin-top:15px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;margin-bottom: 20px;">
                  {{ item.Title }}
                </div>
                <div v-show="transMaga" v-if="item.Title_Translated" :title="item.Title_Translated"
                     style="cursor: pointer;width: 239px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
                     margin-top:15px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                     margin-bottom: 20px">
                  {{ item.Title_Translated }}
                </div>
                <div v-show="false" style="display: flex;justify-content: space-between;align-items: center;height: 50px;
                cursor: pointer;width: 239px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <div v-show="false">2022年02期</div>
                  <div v-show="false"
                       style="display: flex;align-items: center;justify-content: center;font-size: 12px;">
                    <img src="../assets/yinzi.png" alt="" style="margin-right: 6px"/>
                    54352
                  </div>
                </div>
              </router-link>
              <div style="width: 239px;height: 1px;background: #DDDDDD;"></div>
              <div
                  style="display: flex;margin-top: 20px;margin-bottom: 20px;width: 239px;justify-content: space-between;">
                <div
                    style="flex-flow: column;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img src="../assets/read.png" alt="" width="39" height="28"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                    <div style="margin-left: 8px">阅读</div>
                    <div style="margin-left: 8px">{{
                        formatCount(dataStatJson ? dataStatJson[item.id] ? dataStatJson[item.id].read || 0 : 0 : 0)
                      }}
                    </div>
                  </div>
                </div>
                <div
                    style="flex-flow: column;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img src="../assets/down.png" alt="" width="31" height="29"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                    <div style="margin-left: 8px">下载</div>
                    <span style="margin-left: 8px">{{
                        formatCount(dataStatJson ? dataStatJson[item.id] ? dataStatJson[item.id].down || 0 : 0 : 0)
                      }}</span>
                  </div>
                </div>
                <div @click="collDoc(item,index)"
                     style="flex-flow: column;cursor: pointer;align-items: center;display: flex;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  <img v-show="!docIds[item.id]" src="../assets/collection.png" alt="" width="34"
                       height="29"/>
                  <img v-show="docIds[item.id]" src="../assets/collection-ok.png" alt="" width="34"
                       height="29" style="display: none"/>
                  <div
                      style="margin-top: 3px;display: flex;font-size: 12px;font-family: Source Han Sans CN;
                      font-weight: 400;color: #999999;">
                    <el-button type="text" :loading="collDocLoading===index" style="margin-left: 8px;padding: 0">
                        <span style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                          收藏
                        </span>
                    </el-button>
                    <span style="margin-left: 8px">{{ formatCount(collectJson[item.id] || 0) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="jinCai1"
             style="width:100%;overflow-x:hidden;">
          <div style="justify-content: center;width: 100%;margin-top: 50px;display: flex;margin-bottom: 40px">
            <div style="font-size: 30px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;
                display: flex;align-items: center;justify-content: center;">
              <div style="width: 54px;height: 2px;background: #E5E5E5;"></div>
              <div style="margin: 0 10px;display: flex;align-items: center;justify-content: center;">精彩阅读</div>
              <div style="width: 54px;height: 2px;background: #E5E5E5;"></div>
            </div>
          </div>
          <el-carousel height="722px" direction="vertical" :indicator-position="'none'">
            <el-carousel-item v-for="item in 3" :key="item">
              <div v-if="item===1"
                   class="acrousel-item">
                <div class="item"
                     v-for="(item,index) in (jinCai1||[])" :key="index">
                  <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id
                      }}">
                    <div v-show="!transPerio" v-if="item&&item.Title"
                         class="title">
                      <span v-html="item.Title"></span>
                    </div>
                    <div v-show="transPerio" v-if="item&&item.Title_Translated"
                         class="title">
                      <span v-html="item.Title_Translated"></span>
                    </div>
                  </router-link>
                  <div v-show="!transPerio" v-if="item&&item.Creator"
                       style="display:flex;flex-wrap: wrap;margin-top: 14px;font-size: 14px;">
                    <div
                        style="margin-right: 4px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: rgba(153, 153, 153, 1);">
                      作者:
                    </div>
                    <div class="creator">
                      <span
                          v-for="(name, index) in item.Creator?item.Creator.split('%%'):[]"
                          :key="index">
                            <span v-html="name"></span>
                            <span
                                v-if="item.Creator&&index!==item.Creator.split('%%').length-1">,</span>
                          </span>
                    </div>
                    <div v-if="item&&item.PublishYear"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-left: 20px">
                      {{ item.PublishYear }},
                    </div>
                    <div v-if="item&&item.Volum"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ item.Volum }}
                    </div>
                    <div v-if="item&&item.Issue"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      ({{ item.Issue }})
                    </div>
                  </div>
                  <div v-show="transPerio" v-if="item&&item.Creator_Translated"
                       style="display:flex;flex-wrap: wrap;margin-top: 14px;font-size: 14px;">
                    <div
                        style="margin-right: 4px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: rgba(153, 153, 153, 1);">
                      作者:
                    </div>
                    <div class="creator">
                      <span
                          v-for="(name, index) in item.Creator_Translated?item.Creator_Translated.split('%%'):[]"
                          :key="index">
                            <span v-html="name"></span>
                            <span
                                v-if="item.Creator_Translated&&index!==item.Creator_Translated.split('%%').length-1">,</span>
                          </span>
                    </div>
                    <div v-if="item&&item.PublishYear"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-left: 20px">
                      {{ item.PublishYear }},
                    </div>
                    <div v-if="item&&item.Volum"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ item.Volum }}
                    </div>
                    <div v-if="item&&item.Issue"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      ({{ item.Issue }})
                    </div>
                  </div>
                  <div v-show="!transPerio" v-if="item&&item.Abstract"
                       style="margin-top: 17px;height: 66px;">
                    <div class="abs">
                      <span style="margin-right: 10px">摘要:</span>
                      {{ item.Abstract }}
                    </div>
                  </div>
                  <div v-show="transPerio" v-if="item&&item.Abstract_Translated"
                       style="margin-top: 17px;height: 66px;">
                    <div class="abs">
                      <span style="margin-right: 10px">摘要:</span>
                      {{ item.Abstract_Translated }}
                    </div>
                  </div>
                  <div style="margin-top: 20px">
                    <img src="../assets/zan.png" alt=""/>
                    <span style="font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ jinCaiZanCount[item.id] || 0 }}
                    </span>
                  </div>
                  <div class="fg"></div>
                </div>
              </div>
              <div v-if="item===2"
                   class="acrousel-item">
                <div class="item"
                     v-for="(item,index) in (jinCai2||[])" :key="index">
                  <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id
                      }}">
                    <div v-show="!transPerio" v-if="item&&item.Title"
                         class="title">
                      <span v-html="item.Title"></span>
                    </div>
                    <div v-show="transPerio" v-if="item&&item.Title_Translated"
                         class="title">
                      <span v-html="item.Title_Translated"></span>
                    </div>
                  </router-link>
                  <div v-show="!transPerio" v-if="item&&item.Creator"
                       style="display:flex;flex-wrap: wrap;margin-top: 14px;font-size: 14px;">
                    <div
                        style="margin-right: 4px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: rgba(153, 153, 153, 1);">
                      作者:
                    </div>
                    <div class="creator">
                      <span
                          v-for="(name, index) in item.Creator?item.Creator.split('%%'):[]"
                          :key="index">
                            <span v-html="name"></span>
                            <span
                                v-if="item.Creator&&index!==item.Creator.split('%%').length-1">,</span>
                          </span>
                    </div>
                    <div v-if="item&&item.PublishYear"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-left: 20px">
                      {{ item.PublishYear }},
                    </div>
                    <div v-if="item&&item.Volum"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ item.Volum }}
                    </div>
                    <div v-if="item&&item.Issue"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      ({{ item.Issue }})
                    </div>
                  </div>
                  <div v-show="transPerio" v-if="item&&item.Creator_Translated"
                       style="display:flex;flex-wrap: wrap;margin-top: 14px;font-size: 14px;">
                    <div
                        style="margin-right: 4px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: rgba(153, 153, 153, 1);">
                      作者:
                    </div>
                    <div class="creator">
                      <span
                          v-for="(name, index) in item.Creator_Translated?item.Creator_Translated.split('%%'):[]"
                          :key="index">
                            <span v-html="name"></span>
                            <span
                                v-if="item.Creator_Translated&&index!==item.Creator_Translated.split('%%').length-1">,</span>
                          </span>
                    </div>
                    <div v-if="item&&item.PublishYear"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-left: 20px">
                      {{ item.PublishYear }},
                    </div>
                    <div v-if="item&&item.Volum"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ item.Volum }}
                    </div>
                    <div v-if="item&&item.Issue"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      ({{ item.Issue }})
                    </div>
                  </div>
                  <div v-show="!transPerio" v-if="item&&item.Abstract"
                       style="margin-top: 17px;height: 66px;">
                    <div class="abs">
                      <span style="margin-right: 10px">摘要:</span>
                      {{ item.Abstract }}
                    </div>
                  </div>
                  <div v-show="transPerio" v-if="item&&item.Abstract_Translated"
                       style="margin-top: 17px;height: 66px;">
                    <div class="abs">
                      <span style="margin-right: 10px">摘要:</span>
                      {{ item.Abstract_Translated }}
                    </div>
                  </div>
                  <div style="margin-top: 20px">
                    <img src="../assets/zan.png" alt=""/>
                    <span style="font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ jinCaiZanCount[item.id] || 0 }}
                    </span>
                  </div>
                  <div class="fg"></div>
                </div>
              </div>
              <div v-if="item===3"
                   class="acrousel-item">
                <div class="item"
                     v-for="(item,index) in (jinCai3||[])" :key="index">
                  <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id
                      }}">
                    <div v-show="!transPerio" v-if="item&&item.Title"
                         class="title">
                      <span v-html="item.Title"></span>
                    </div>
                    <div v-show="transPerio" v-if="item&&item.Title_Translated"
                         class="title">
                      <span v-html="item.Title_Translated"></span>
                    </div>
                  </router-link>
                  <div v-show="!transPerio" v-if="item&&item.Creator"
                       style="display:flex;flex-wrap: wrap;margin-top: 14px;font-size: 14px;">
                    <div
                        style="margin-right: 4px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: rgba(153, 153, 153, 1);">
                      作者:
                    </div>
                    <div class="creator">
                      <span
                          v-for="(name, index) in item.Creator?item.Creator.split('%%'):[]"
                          :key="index">
                            <span v-html="name"></span>
                            <span
                                v-if="item.Creator&&index!==item.Creator.split('%%').length-1">,</span>
                          </span>
                    </div>
                    <div v-if="item&&item.PublishYear"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-left: 20px">
                      {{ item.PublishYear }},
                    </div>
                    <div v-if="item&&item.Volum"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ item.Volum }}
                    </div>
                    <div v-if="item&&item.Issue"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      ({{ item.Issue }})
                    </div>
                  </div>
                  <div v-show="transPerio" v-if="item&&item.Creator_Translated"
                       style="display:flex;flex-wrap: wrap;margin-top: 14px;font-size: 14px;">
                    <div
                        style="margin-right: 4px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: rgba(153, 153, 153, 1);">
                      作者:
                    </div>
                    <div class="creator">
                      <span
                          v-for="(name, index) in item.Creator_Translated?item.Creator_Translated.split('%%'):[]"
                          :key="index">
                            <span v-html="name"></span>
                            <span
                                v-if="item.Creator_Translated&&index!==item.Creator_Translated.split('%%').length-1">,</span>
                          </span>
                    </div>
                    <div v-if="item&&item.PublishYear"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;margin-left: 20px">
                      {{ item.PublishYear }},
                    </div>
                    <div v-if="item&&item.Volum"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ item.Volum }}
                    </div>
                    <div v-if="item&&item.Issue"
                         style="display: flex;align-items: flex-end;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      ({{ item.Issue }})
                    </div>
                  </div>
                  <div v-show="!transPerio" v-if="item&&item.Abstract"
                       style="margin-top: 17px;height: 66px;">
                    <div class="abs">
                      <span style="margin-right: 10px">摘要:</span>
                      {{ item.Abstract }}
                    </div>
                  </div>
                  <div v-show="transPerio" v-if="item&&item.Abstract_Translated"
                       style="margin-top: 17px;height: 66px;">
                    <div class="abs">
                      <span style="margin-right: 10px">摘要:</span>
                      {{ item.Abstract_Translated }}
                    </div>
                  </div>
                  <div style="margin-top: 20px">
                    <img src="../assets/zan.png" alt=""/>
                    <span style="font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      {{ jinCaiZanCount[item.id] || 0 }}
                    </span>
                  </div>
                  <div class="fg"></div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import {searchServiceClient, getHotRequest, getJinCaiRequest, facetRequest, collRequest} from "@/tools/search-api";
import {authServiceClient, outRequest, userStatusRequest} from "@/tools/auth-api"
import {Base64} from "js-base64";
import jwt from "jwt-decode";

export default {
  name: 'Home',
  data() {
    return {
      watchList: [
        [3465, 4102, 2944],
        [3312, 3500, 3032],
        [3774, 4577, 3652],
        [3065, 4252, 2553],
        [3376, 4588, 3780],
        [3369, 3866, 2995],
        [4521, 5018, 3699],
        [3997, 4556, 3899],
        [3818, 4015, 3567],
        [4775, 5001, 4125],
        [3769, 4069, 3515],
        [3922, 4237, 3648],
      ],
      isShow: true,
      word: '',
      select: '所有领域',
      collection: 'Periodical',
      jinCaiZanCount: {},
      hot1: [],
      hot2: [],
      hot3: [],
      jinCai: {},
      jinCai1: [],
      jinCai2: [],
      jinCai3: [],
      dataStatJson: {},
      collectJson: {},
      oldToken: '',
      groupUserInfos: [],
      userInfo: '',
      userName: '',
      showloginOut: false,
      groupUserInfo: '',
      userId: '',
      userType: '',
      checkLogin: undefined,
      facetFieldDataClassCode: [],
      collDocLoading: -1,
      docIds: {},
    }
  },
  computed: {
    transMaga() {
      return this.$store.state.trans
    },
    transPerio() {
      return this.$store.state.trans
    }
  },
  created() {
    this.facetPerioClassCode()
    let that = this
    this.isShow = screen.width >= 1920
    let token = this.$cookies.get('token')
    if (!token) {
      token = localStorage.getItem('token');
    }
    searchServiceClient.getJinCai(getJinCaiRequest, {['token']: token}).then(res => {
      if (res.getJinCai()) {
        that.jinCai = JSON.parse(res.getJinCai())
        let jinCai = JSON.parse(res.getJinCai())
        if (that.isShow) {
          debugger
          that.jinCai1 = jinCai.documents.splice(0, 9)
          that.jinCai2 = jinCai.documents.splice(0, 9)
          that.jinCai3 = jinCai.documents.splice(0, 9)
        } else {
          that.jinCai1 = jinCai.documents.splice(0, 6)
          that.jinCai2 = jinCai.documents.splice(0, 6)
          that.jinCai3 = jinCai.documents.splice(0, 6)
        }
        that.jinCaiZanCount = JSON.parse(res.getZanJson())
      }
    }).catch(err => {
      console.log(err)
    })
    searchServiceClient.getHot(getHotRequest, {['token']: token}).then(res => {
      if (res.getHot()) {
        let hot = JSON.parse(res.getHot())
        if (that.isShow) {
          that.hot1 = hot.documents.splice(0, 6)
          that.hot2 = hot.documents.splice(0, 6)
          that.hot3 = hot.documents.splice(0, 6)
        } else {
          that.hot1 = hot.documents.splice(0, 5)
          that.hot2 = hot.documents.splice(0, 5)
          that.hot3 = hot.documents.splice(0, 5)
        }
        that.dataStatJson = JSON.parse(res.getDataStatJson())
        that.collectJson = JSON.parse(res.getCollectJson())
        that.docIds = JSON.parse(res.getDocIdJson())
      }
    }).catch(err => {
      console.log(err)
    })
    authServiceClient.getUserStatus(userStatusRequest, {
      ['token']: token
    }).then(res => {
      if (!res.getStatus()) {
        localStorage.removeItem("token")
        localStorage.removeItem("auth-context")
        that.userInfo = "";
        that.groupUserInfo = "";
        that.showRoaming = false;
        return;
      }
      const user = localStorage.getItem('auth-context');
      if (!user) {
        return
      }
      const userJson = JSON.parse(user);
      const Base64 = require('js-base64').Base64;
      userJson.forEach(item => {
        if (item.type === 'Person' || item.type === 'Child') {
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          if (item.type === 'Person') {
            this.userType = '0'
          }
          if (item.type === 'Child') {
            this.userType = '1'
          }
          this.userInfo = realName
          this.userId = item.name;
          if (!item.isRoaming) {
            let roaming = localStorage.getItem(this.userId)
            if (!roaming || roaming !== '1') {
              this.showRoaming = true
            } else {
              this.showRoaming = false
              this.userType = '2'
            }
          } else {
            this.userType = '2'
          }
          if (this.groupUserInfo === '') {
            this.groupUserInfo = '机构登录';
          }
          this.showloginOut = true
        }
        if (item.type === 'Group') {
          this.groupUserInfos.push(item)
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          this.groupUserInfo = realName;
          this.url = process.env.VUE_APP_WEB_LOGIN_URL;
        }
      })
    }).catch(err => {
      localStorage.removeItem("token")
      localStorage.removeItem("auth-context")
      this.userInfo = "";
    });
  },
  watch: {
    collection(newVal, oldVal) {
      if (newVal === 'Periodical') {
        if (["所有领域", "题名", "作者", "刊名", "作者单位", "关键词"].indexOf(this.select) === -1) {
          this.select = "所有领域"
        }
      } else {
        if (["所有领域", "题名"].indexOf(this.select) === -1) {
          this.select = "所有领域"
        }
      }
    }
  },
  methods: {
    collDoc(item, index) {
      this.collDocLoading = index
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.collDocLoading = -1
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let href = "https://hy.wesitrans.com" + this.$router.resolve({
        name: 'MagazineInfo',
        query: {
          collection: 'Magazine',
          id: item.id,
          trans: true
        }
      }).href;
      let request = collRequest
      request.setDocId(item.id)
      request.setCollection('Magazine')
      request.setUrl(href)
      searchServiceClient.coll(request, {
        ['token']: token
      }).then(res => {
        if (res.getMessage() === "login") {
          this.collDocLoading = -1
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getMessage() === 'login-person') {
          this.collDocLoading = -1
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getMessage() === "remote") {
          this.collDocLoading = -1
          this.$message.error("当前账户不具备收藏权限,请联系销售人员！")
          return
        }
        if (!res.getStatus()) {
          this.collDocLoading = -1
          this.$message.error(res.getMessage())
          return
        }
        this.collDocLoading = -1
        this.$message.success(res.getMessage())
        if (this.docIds[item.id]) {
          this.docIds[item.id] = !this.docIds[item.id]
          this.collectJson[item.id] = this.collectJson[item.id] - 1
        } else {
          this.docIds[item.id] = true
          this.collectJson[item.id] = (this.collectJson[item.id] || 0) + 1
        }
      }).catch(err => {
        this.collDocLoading = -1
        this.$message.error("收藏异常，请稍后再试！")
      })
    },
    formatCount(val) {
      let nVal = ['', '', ''];
      let fr = 1000;
      let num = 3;
      while (val / fr >= 1) {
        fr *= 10;
        num += 1;
      }
      if (num <= 4) { // 千
        nVal[1] = '千+';
        nVal[0] = parseInt(val / 1000) + '';
      } else if (num <= 8) { // 万
        const str = parseInt(num - 4) / 3 > 1 ? '千万+' : '万+';
        const fm = '万+' === str ? 10000 : 10000000;
        nVal[1] = str;
        nVal[0] = (val / fm) + '';
      } else if (num <= 16) {// 亿
        let str = (num - 8) / 3 > 1 ? '千亿+' : '亿+';
        str = (num - 8) / 4 > 1 ? '万亿+' : str;
        str = (num - 8) / 7 > 1 ? '千万亿+' : str;
        let fm = 1;
        if ('亿+' === str) {
          fm = 100000000;
        } else if ('千亿+' === str) {
          fm = 100000000000;
        } else if ('万亿+' === str) {
          fm = 1000000000000;
        } else if ('千万亿+' === str) {
          fm = 1000000000000000;
        }
        nVal[1] = str;
        nVal[0] = parseInt(val / fm) + '';
      }
      if (val < 1000) {
        nVal[1] = '';
        nVal[0] = val + '';
      }
      return nVal.join('');
    },
    facetPerioClassCode() {
      let request = facetRequest;
      request.setCurrentPage(0)
      request.setPageSize(10000)
      request.setFilterQueryList([])
      request.setDefaultField('所有领域')
      request.setFacetQueryWord("*")
      request.setCollection("Magazine")
      request.setFacetFieldList(["期刊分类"])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facetPerioClassCode(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          return
        }
        this.facetFieldDataClassCode = JSON.parse(document)
      }).catch(err => {
        console.log(err)
      })

    },
    toNavigation(val) {
      this.$router.push({
        path: "/navigation",
        query: {
          collection: "Magazine",
          select: "所有领域",
          word: "",
          currentPage: 1,
          pageSize: 10,
          filter: [],
          trans: true,
          radio: val
        }
      })
    },
    toMore() {
      this.$router.push("/navigation")
    },
    transMagas() {
      this.$store.commit("transHandle")
    },
    transPerios() {
      this.transPerio = !this.transPerio
    },
    logOut(val) {
      let request = outRequest;
      if (val === undefined || val === "" || val === null || val === {}) {
        request.setName(this.userId)
      } else {
        request.setName(val)
      }
      const TOKEN_KEY = 'token';
      authServiceClient.logOut(request, {
        [TOKEN_KEY]: localStorage.getItem(TOKEN_KEY)
      }).then(res => {
        this.$router.push("/")
      }).catch(err => {
        localStorage.removeItem("token")
        localStorage.removeItem("auth-context")
        this.userInfo = "";
        this.$router.push("/")
      });
    },
    toList() {
      window.open(this.$router.resolve('/list').href, '_bank')
    },
    toCenter() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    toWesi() {
      window.open("https://wesitrans.com", "_bank")
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    clearWord() {
      this.word = ""
    },
    checkSource(collection) {
      this.collection = collection
    },
    toSearch() {
      if (this.word === '' || this.isEmpty(this.word)) {
        this.$message.warning("请输入检索式")
        return
      }
      this.$router.push({
        name: this.collection,
        query: {
          collection: this.collection,
          select: this.select,
          word: this.word,
          currentPage: 1,
          pageSize: 10,
          filter: [],
          trans: true
        }
      })
    },
    isEmpty(val) {
      if (val == null) return true;
      if (typeof val === 'boolean') return false;
      if (typeof val === 'number') return !val;
      if (val instanceof Error) return val.message === '';
      switch (Object.prototype.toString.call(val)) {
        case '[object String]':
        case '[object Array]':
          return !val.length;
        case '[object File]':
        case '[object Map]':
        case '[object Set]': {
          return !val.size;
        }
        case '[object Object]': {
          return !Object.keys(val).length;
        }
      }
      return false;
    }
  },
  destroyed() {
    clearInterval(this.checkLogin)
  }
}
</script>
<style lang="less">
.el-popover {
  min-width: 120px !important;
}


a {
  text-decoration: none;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #ffffff;
}

.el-select-dropdown {
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666;
  }

  .el-select-dropdown__item.selected {
    color: #231815;
    font-weight: 700;
  }
}
</style>
<style lang="less" scoped>
.left-yijian-qq {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  box-shadow: 0 0 15px #9f9696;
  cursor: pointer;
  text-align: center;
  width: 50px;
  height: 220px;
  background: #ffffff;
  border-radius: 6px;
  left: auto;
  color: #ffffff;
  font-size: 12px;
  position: fixed;
  bottom: 105px;
  right: 0;
  font-family: 'Microsoft YaHei';
  z-index: 19;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
}

@media (max-width: 1919px) {
  .home {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    .acrousel-item {
      flex-wrap: wrap;
      line-height: 22px;
      margin-left: 40px;
      margin-right: 40px;
      display: flex;
      margin-top: 26px;
      justify-content: space-between;
      min-width: 1366px;

      .item {
        width: 660px;
        height: 240px;
        display: flex;
        flex-flow: column;

        .title {
          cursor: pointer;
          width: 660px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #231815;
        }

        .creator {
          color: rgb(102, 102, 102);
          margin-left: 10px;
          width: 500px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .abs {
          width: 660px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgb(102, 102, 102);
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          height: 66px;
        }

        .fg {
          width: 660px;
          margin-top: 23px;
          height: 2px;
          border-bottom: 1px solid #DDDDDD;
        }
      }
    }

    .home-content {
      margin: 0 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 76px;
    }

    .nav-content {
      margin: 0 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .hot {
      margin-left: 40px;
      margin-right: 40px;
      display: flex;
      flex-flow: column;
      align-items: center
    }

    .head {
      background: url('../assets/back_1366.jpg') no-repeat;
      background-size: 1660px 281px;
      height: 281px;
      width: 100%;
      display: flex;
      flex-flow: column;

      .head-el {
        height: 281px !important;

        .head-one {
          width: 800px;
          height: 281px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          margin-left: 120px;
        }
      }
    }

    .active {
      width: 44px;
      border-bottom: 4px solid #E83428;
    }

    .no-active {
      width: 44px;
      border-bottom: 4px solid white;
    }

    .user-info {
      height: 76px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2C211E;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .login-btn-group {
      width: 100px;
      height: 33px;
      background: linear-gradient(-50deg, #231815, #453835);
      opacity: 0.96;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .login-btn {
      width: 66px;
      height: 33px;
      background: linear-gradient(-50deg, #231815, #453835);
      opacity: 0.96;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    /deep/ .el-header {
      padding: 0;
    }

    /deep/ .el-main {
      padding: 0;
      display: flex;
      flex-flow: column;
      align-items: center;
      background: white;
      overflow: hidden;
    }

    .mang-lists {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 526px;
      min-width: 1360px;

      .mang-list-item {
        width: 272px;
        height: 506px;
        background: #FFFFFF;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
      }
    }

    /deep/ .el-select .el-input {
      width: 180px;
    }

    .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }

    .check-source {
      display: flex;
      cursor: pointer;

      .check-active {
        width: 96px;
        height: 36px;
        background: #DCDED9;
        border-radius: 5px 5px 0px 0px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #231815;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .check-no-active {
        width: 96px;
        height: 36px;
        background: rgb(220 222 217 / 0%);
        border-radius: 5px 5px 0px 0px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #231815;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    /deep/ .search-input {
      height: 51px;
      background: #FFFFFF;
      border: 0px solid #FFFFFF;

      .el-select .el-input .el-select__caret.is-reverse {
        transform: rotateZ(180deg);
      }

      .el-select .el-input .el-select__caret {
        transform: rotateZ(0);
      }

      .btn-search {
        width: 110px;
        height: 51px;
        background: linear-gradient(-50deg, #231815, #453835);
        opacity: 0.96;
        border-radius: 0px 5px 5px 0px;
        margin-right: -22px;

        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .el-input__suffix {
        display: flex;
        align-items: center;
        padding-right: 16px;
        cursor: pointer;
      }

      .el-icon-arrow-up:before {
        content: url("../assets/sanjiao.png");
      }

      .el-input-group {
        height: 51px
      }

      .el-input-group__append, .el-input-group__prepend {
        padding: 0;
      }

      .el-input-group__append .el-button, .el-input-group__append .el-select, .el-input-group__prepend .el-button, .el-input-group__prepend .el-select {
        margin: 0;
      }

      .el-input-group--append .el-input__inner, .el-input-group__prepend {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 51px;
        border: 0;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }

      .el-input-group__append {
        border: 0;
        background: white;
        width: 155px;
        padding-right: 0;
        border-left: 1px solid #DDDDDD;

        .el-input__inner {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 56px;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .home {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    .acrousel-item {
      flex-wrap: wrap;
      line-height: 22px;
      margin-left: 100px;
      margin-right: 100px;
      display: flex;
      margin-top: 26px;
      justify-content: space-between;

      .item {
        width: 560px;
        height: 240px;
        display: flex;
        flex-flow: column;

        .title {
          cursor: pointer;
          width: 538px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #231815;
        }

        .creator {
          color: rgb(102, 102, 102);
          margin-left: 10px;
          width: 390px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .abs {
          width: 538px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgb(102, 102, 102);
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          height: 66px;
        }

        .fg {
          width: 535px;
          margin-top: 23px;
          height: 2px;
          border-bottom: 1px solid #DDDDDD;
        }
      }
    }

    .home-content {
      margin: 0 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 76px;
    }

    .nav-content {
      margin: 0 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .hot {
      margin-left: 100px;
      margin-right: 100px;
      display: flex;
      flex-flow: column;
      align-items: center
    }

    .head {
      background: url('../assets/head-back-1920.jpg') no-repeat;
      background-size: 1922px 281px;
      height: 281px;
      width: 100%;
      display: flex;
      flex-flow: column;

      .head-el {
        height: 281px !important;

        .head-one {
          width: 800px;
          height: 281px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          margin-left: 362px;
        }
      }
    }

    .active {
      width: 44px;
      border-bottom: 4px solid #E83428;
    }

    .no-active {
      width: 44px;
      border-bottom: 4px solid white;
    }

    .user-info {
      height: 76px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2C211E;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .login-btn-group {
      width: 100px;
      height: 33px;
      background: linear-gradient(-50deg, #231815, #453835);
      opacity: 0.96;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .login-btn {
      width: 66px;
      height: 33px;
      background: linear-gradient(-50deg, #231815, #453835);
      opacity: 0.96;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    /deep/ .el-header {
      padding: 0;
    }

    /deep/ .el-main {
      padding: 0;
      width: 1920px;
      display: flex;
      flex-flow: column;
      align-items: center;
      background: white;
      overflow: hidden;
    }

    .mang-lists {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .mang-list-item {
        width: 286px;
        height: 506px;
        background: #FFFFFF;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
      }
    }

    /deep/ .el-select .el-input {
      width: 180px;
    }

    .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }

    .check-source {
      display: flex;
      cursor: pointer;

      .check-active {
        width: 96px;
        height: 36px;
        background: #DCDED9;
        border-radius: 5px 5px 0px 0px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #231815;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .check-no-active {
        width: 96px;
        height: 36px;
        background: rgb(220 222 217 / 0%);
        border-radius: 5px 5px 0px 0px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #231815;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    /deep/ .search-input {
      height: 51px;
      background: #FFFFFF;
      border: 0px solid #FFFFFF;

      .el-select .el-input .el-select__caret.is-reverse {
        transform: rotateZ(180deg);
      }

      .el-select .el-input .el-select__caret {
        transform: rotateZ(0);
      }

      .btn-search {
        width: 110px;
        height: 51px;
        background: linear-gradient(-50deg, #231815, #453835);
        opacity: 0.96;
        border-radius: 0px 5px 5px 0px;
        margin-right: -22px;

        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .el-input__suffix {
        display: flex;
        align-items: center;
        padding-right: 16px;
        cursor: pointer;
      }

      .el-icon-arrow-up:before {
        content: url("../assets/sanjiao.png");
      }

      .el-input-group {
        height: 51px
      }

      .el-input-group__append, .el-input-group__prepend {
        padding: 0;
      }

      .el-input-group__append .el-button, .el-input-group__append .el-select, .el-input-group__prepend .el-button, .el-input-group__prepend .el-select {
        margin: 0;
      }

      .el-input-group--append .el-input__inner, .el-input-group__prepend {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 51px;
        border: 0;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }

      .el-input-group__append {
        border: 0;
        background: white;
        width: 155px;
        padding-right: 0;
        border-left: 1px solid #DDDDDD;

        .el-input__inner {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 56px;
        }
      }
    }
  }
}
</style>
