import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import cookies from "vue-cookies";
import {Base64} from "js-base64";

Vue.use(VueRouter)
Vue.use(cookies)
const routes = [
    {
        path: '/index',
        redirect: '/',
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/periodical',
        name: 'Periodical',
        component: () => import(/* webpackChunkName: "about" */ '../views/Periodical.vue')
    },
    {
        path: '/magazine',
        name: 'Magazine',
        component: () => import(/* webpackChunkName: "about" */ '../views/Magazine.vue')
    },
    {
        path: '/document',
        name: 'Document',
        component: () => import(/* webpackChunkName: "about" */ '../views/Document.vue')
    },
    {
        path: '/magazine-info',
        name: 'MagazineInfo',
        component: () => import('../views/MagazineInfo.vue')
    },
    {
        path: '/read-view',
        name: 'ReadView',
        component: () => import('../views/ReadView.vue')
    },
    {
        path: '/list',
        name: 'List',
        component: () => import('../views/List.vue')
    },
    {
        path: '/navigation',
        name: 'MagazineNav',
        component: () => import('../views/MagazineNav.vue')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.name !== 'List' && to.name !== 'ReadView') {
        next();
        return;
    } else {
        let token = localStorage.getItem('token');
        if (!token) {
            next("/index?key=login");
            return
        }
        let authContext = localStorage.getItem('auth-context');
        if (!authContext) {
            next("/index?key=login");
            return
        } else {
            const userJson = JSON.parse(authContext);
            for (let i = 0; i < userJson.length; i++) {
                if (userJson[i].type === 'Person') {
                    next("/index?key=login&type=no");
                    return
                }
            }
        }
    }
    next();
})

export default router
