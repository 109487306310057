import {authServiceClient} from './service-auth'
import {
  LoginRequest,
  ValicodeRequest,
  RegisterRequest,
  CheckPhoneRequest,
  CheckUserNameRequest,
  UserStatusRequest,
  OutRequest,
  UserPowerRequest,
  UserIpRequest,
  RecommendationsRequest,
  TryOutRequest,
  PhoneCodeRequest,
  PhoneLoginRequest,
  CheckPhoneCodeRequest,
  ResetPasswordRequest,
  GetAppIdRequest,
  ThirdLoginRequest,
  PhoneBindRequest
} from '../../api/authService_pb'
let phoneBindRequest = new PhoneBindRequest();
let thirdLoginRequest = new ThirdLoginRequest();
let getAppIdRequest = new GetAppIdRequest();
const checkPhoneCodeRequest = new CheckPhoneCodeRequest();
let phoneLoginRequest = new PhoneLoginRequest();
let phoneCodeRequest = new PhoneCodeRequest();
const loginRequest = new LoginRequest();
const valicodeRequest = new ValicodeRequest();
const registerRequest = new RegisterRequest();
const checkPhoneRequest = new CheckPhoneRequest();
const checkUserNameRequest = new CheckUserNameRequest();
const userStatusRequest = new UserStatusRequest();
const outRequest = new OutRequest();
const userPowerRequest = new UserPowerRequest();
const userIpRequest = new UserIpRequest();
const recommendationsRequest = new RecommendationsRequest();
const tryOutRequest = new TryOutRequest();
let resetPasswordRequest = new ResetPasswordRequest();
export {
  loginRequest,
  authServiceClient,
  valicodeRequest,
  registerRequest,
  checkPhoneRequest,
  checkUserNameRequest,
  userStatusRequest,
  outRequest,
  userPowerRequest,
  userIpRequest,
  recommendationsRequest,
  tryOutRequest,
  phoneCodeRequest,
  phoneLoginRequest,
  checkPhoneCodeRequest,
  resetPasswordRequest,
  getAppIdRequest,
  thirdLoginRequest,
  phoneBindRequest
}
