<template>
  <div id="app">
    <div v-show="this.$route.name!=='Home'"  style="z-index: 10;width: 100%;background: #FFFFFF;display: flex;align-items: end;justify-content: center">
      <div style="margin: 0 100px;width:100%;display: flex;justify-content: space-between;height: 76px;">
        <div style="width: 450px;display: flex;align-items: end;">
          <img src="./assets/logo.png" alt="" width="203" height="70"
               style="cursor: pointer;"  @click="()=>this.$router.push('/')">
          <div
              style="margin-left: 117px;width: 130px;display: flex;align-items: center;justify-content: space-between;">
            <div
                style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;cursor:pointer;height: 72px;display: flex;align-items: center;justify-content: center;"
                :class="'no-active'"
                @click="()=>this.$router.push('/')">首页
            </div>
            <div @click="toMore" :class="'no-active'"
                 style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;cursor:pointer;height: 72px;display: flex;align-items: center;justify-content: center"
            >
              期刊
            </div>
          </div>
        </div>
        <div style="display:flex;height: 76px;display: flex;align-items: center;">
          <div style="display: flex;width: 106px;justify-content: space-between;" @click="toWesi">
            <img style="cursor: pointer" src="./assets/wesi.png" alt="" width="22" height="22"/>
            <div style="cursor: pointer;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;">翻译站点</div>
          </div>
          <div style="margin-left: 28px;margin-right: 36px;height: 76px;display: flex;align-items: center;">
            <img v-show="!$store.state.trans" style="cursor: pointer" src="./assets/译icon-A.png" alt="" @click="transMagas"/>
            <img v-show="$store.state.trans" style="cursor: pointer" src="./assets/译icon-文原.png" alt="" @click="transMagas"/>
          </div>
          <div v-if="groupUserInfo==='机构登录'"
               style="margin-right: 15px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #2C211E;cursor: pointer"
               @click="showIframe('/loginTwo?code=haoyi')">
            机构登录
          </div>
          <div v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'" class="user-info"
               @click="showIframe('/loginTwo?code=haoyi')">
            <el-popover
                class="jigou"
                placement="bottom"
                title=""
                width="120"
                trigger="hover">
              <div
                  style="cursor:pointer;width: 120px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;line-height: 36px">
                <div v-for="(item,index) in groupUserInfos" @click="toCenter" style="display: flex">
                  <div :title="item.name"
                       style="width: 80px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
                    {{
                      item.name
                    }}
                  </div>
                  <span @click="logOut(item.name)">退出</span>
                </div>
              </div>
              <div :title="groupUserInfo"
                   style="max-width: 300px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap"
                   slot="reference">{{ groupUserInfo }}
              </div>
            </el-popover>
          </div>
          <div v-if="userInfo===''" @click="showIframe('/registerTwo?code=haoyi')"
               style="margin-right: 15px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #2C211E;cursor: pointer">
            注册
          </div>
          <div v-if="userInfo===''" class="login-btn" @click="showIframe('/loginTwo?code=haoyi')">
            登录
          </div>
          <div v-if="userInfo!==''" class="user-info">
            <el-popover
                class="geren"
                placement="bottom"
                title=""
                width="120"
                trigger="hover">
              <div
                  style="cursor:pointer;width: 120px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;line-height: 36px">
                <div @click="toList">个人中心</div>
                <div @click="toCenter">账号设置</div>
                <div @click="logOut()">退出</div>
              </div>
              <div :title="userInfo"
                   slot="reference"
                   style="max-width: 300px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">欢迎，{{
                  userInfo
                }}
              </div>
            </el-popover>
            <span @click="toCenter">
            <img v-if="userType==='0'" class="user-img" src="./assets/person_no.png" alt="">
            <img v-if="userType==='1'" class="user-img" src="./assets/child_no.png" alt="">
            <img v-if="userType==='2'" class="user-img" src="./assets/roaming_no.png" alt="">
        </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <div class="foot">
      © 2020 北京容智科技发展有限公司 京ICP备19036872号-3 版权所有京公网安备
    </div>
  </div>
</template>

<script>
import {authServiceClient} from "@/tools/service-auth"
import {outRequest, userStatusRequest} from "@/tools/auth-api"
import layer from "layui-layer";
import jwt from "jwt-decode";
import {Base64} from "js-base64";

export default {
  name: 'app',
  data() {
    return {
      oldToken: '',
      checkLogin: undefined,
      groupUserInfos: [],
      userInfo: '',
      userName: '',
      showloginOut: false,
      groupUserInfo: '',
      userId: '',
      userType: '',
      transMaga: false,
    }
  },
  updated() {
    let fullPath = this.getUrlParameter(this.$route.fullPath)
    const key = fullPath.key;
    if (key === 'login') {
      const type = fullPath.type;
      if (type === 'no') {
        this.$message.error("当前账号没有操作权限，请登录机构子账号！")
      }
      this.showIframe('/loginTwo?code=haoyi')
    }
  },
  mounted() {
    let token = this.$cookies.get('token')
    if (!token) {
      token = localStorage.getItem('token');
    }
    authServiceClient.getUserStatus(userStatusRequest, {
      ['token']: token
    }).then(res => {
      if (!res.getStatus()) {
        localStorage.removeItem("token")
        localStorage.removeItem("auth-context")
        this.userInfo = "";
        this.groupUserInfo = "";
        this.showRoaming = false;
        return;
      }
      const user = localStorage.getItem('auth-context');
      if (!user) {
        return
      }
      const userJson = JSON.parse(user);
      const Base64 = require('js-base64').Base64;
      userJson.forEach(item => {
        if (item.type === 'Person' || item.type === 'Child') {
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          if (item.type === 'Person') {
            this.userType = '0'
          }
          if (item.type === 'Child') {
            this.userType = '1'
          }
          this.userInfo = realName
          this.userId = item.name;
          if (!item.isRoaming) {
            let roaming = localStorage.getItem(this.userId)
            if (!roaming || roaming !== '1') {
              this.showRoaming = true
            } else {
              this.showRoaming = false
              this.userType = '2'
            }
          } else {
            this.userType = '2'
          }
          if (this.groupUserInfo === '') {
            this.groupUserInfo = '机构登录';
          }
          this.showloginOut = true
        }
        if (item.type === 'Group') {
          this.groupUserInfos.push(item)
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          this.groupUserInfo = realName;
          this.url = process.env.VUE_APP_WEB_LOGIN_URL;
        }
      })
    }).catch(err => {
      localStorage.removeItem("token")
      localStorage.removeItem("auth-context")
      this.userInfo = "";
    });
  },
  methods: {
    toMore() {
      this.$router.push("/navigation")
    },
    transMagas() {
      this.$store.commit("transHandle")
    },
    getUrlParameter(route) {
      //decodeURIComponent() 函数可对 encodeURIComponent() 函数编码的 URI 进行解码。
      let url = decodeURIComponent(route).split('?');
      url = url[url.length - 1];
      let obj = {};
      url.split('&').map((query) => {
        let temp = query.split('=');
        obj[temp[0]] = temp[1];
      });
      return obj;
    },
    toWesi() {
      window.open("https://wesitrans.com", "_bank")
    },
    logOut(val) {
      let request = outRequest;
      if (val === undefined || val === "" || val === null || val === {}) {
        request.setName(this.userId)
      } else {
        request.setName(val)
      }
      const TOKEN_KEY = 'token';
      authServiceClient.logOut(request, {
        [TOKEN_KEY]: localStorage.getItem(TOKEN_KEY)
      }).then(res => {
        this.$router.push("/")
      }).catch(err => {
        localStorage.removeItem("token")
        localStorage.removeItem("auth-context")
        this.userInfo = "";
        this.$router.push("/")
      });
    },
    toList(){
      window.open(this.$router.resolve('/list').href,'_bank')
    },
    toCenter() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        this.$router.push('/')
        window.location.reload()
      }
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
  },
  destroyed() {
    clearInterval(this.checkLogin)
  }
}
</script>
<style>
.el-popover {
  min-width: 120px !important;
}
</style>
<style lang="less" scoped>
#app {
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: visible !important;
  min-width: 1300px;

  .active {
    width: 44px;
    border-bottom: 4px solid #E83428;
  }

  .no-active {
    width: 44px;
    border-bottom: 4px solid white;
  }


  .user-info {
    height: 76px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2C211E;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .login-btn-group {
    width: 100px;
    height: 33px;
    background: linear-gradient(-50deg, #231815, #453835);
    opacity: 0.96;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }

  .login-btn {
    width: 66px;
    height: 33px;
    background: linear-gradient(-50deg, #231815, #453835);
    opacity: 0.96;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }

  .content {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    background: #F5F5F5;
  }

  .foot {
    width: 100%;
    height: 113px;
    background: #231815;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #F5F5F5;
    line-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
