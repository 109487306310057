import {searchServiceClient} from './service'
import {
    QueryRequest,
    FacetRequest,
    DocumentRequest,
    GetJinCaiRequest,
    GetHotRequest,
    DocumentUrlRequest,
    TransDocRequest,
    DownloadRequest,
    CheckStatusRequest,
    ListRequest,
    DeleteRequest,
    SendEmailRequest,
    CollRequest,
    CollListRequest,
    DelCollRequest,
    GetRemarkTypeRequest,
    CheckRemarkRequest
} from '../../api/Search_pb'

let checkRemarkRequest = new CheckRemarkRequest();
let getRemarkTypeRequest = new GetRemarkTypeRequest();
let delCollRequest = new DelCollRequest();
let collListRequest = new CollListRequest();
let collRequest = new CollRequest();
let sendEmailRequest = new SendEmailRequest();
let deleteRequest = new DeleteRequest();
let listRequest = new ListRequest();
let checkStatusRequest = new CheckStatusRequest();
let downloadRequest = new DownloadRequest();
let queryRequest = new QueryRequest();
let facetRequest = new FacetRequest();
let documentRequest = new DocumentRequest();
let getJinCaiRequest = new GetJinCaiRequest();
let getHotRequest = new GetHotRequest();
let documentUrlRequest = new DocumentUrlRequest();
let transDocRequest = new TransDocRequest();
export {
    checkRemarkRequest,
    getRemarkTypeRequest,
    delCollRequest,
    collListRequest,
    collRequest,
    sendEmailRequest,
    deleteRequest,
    listRequest,
    queryRequest,
    facetRequest,
    documentRequest,
    searchServiceClient,
    getJinCaiRequest,
    getHotRequest,
    documentUrlRequest,
    transDocRequest,
    downloadRequest,
    checkStatusRequest
}