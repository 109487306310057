import {AuthServicePromiseClient} from '../../api/authService_grpc_web_pb'
import {TranslateServicePromiseClient} from '../../api/translateService_grpc_web_pb'
import cookies from "vue-cookies";
// 从 env 配置文件读取接口域名
const host = 'https://wesitrans.com';
const allInterceptor = function () {
}
let that = cookies
allInterceptor.prototype.intercept = function (request, invoker) {
    return invoker(request).then((response) => {
        debugger
        const metadata = response.getMetadata();
        if (metadata['code']) {
            localStorage.setItem('code', metadata['code']);
        }
        if (metadata['auth-context'] || metadata['token']) {
            localStorage.setItem('auth-context', metadata['auth-context']);
            localStorage.setItem('token', metadata['token']);
            that.set("token", metadata['token'], 30 * 60 * 1000, '/', '.wesitrans.com', true, 'none')
        }
        return response;
    });
}

const interceptor = new allInterceptor()
export const translateServiceClient = new TranslateServicePromiseClient(host, null, {
    'unaryInterceptors': [interceptor]
});
export const authServiceClient = new AuthServicePromiseClient(host, null, {
    'unaryInterceptors': [interceptor]
});
